import React from 'react';
import styles from './text.module.scss';

export const Subheading = ({ children, className, props, style }) => {
  return (
    <h4
      className={[styles.sub__heading, className].join(' ')}
      {...props}
      style={style}
    >
      {children || 'This is a SubHeading'}
    </h4>
  );
};

export const SmallText = ({ children, className, props, style }) => {
  return (
    <p
      className={[styles.small__text, className].join(' ')}
      {...props}
      style={style}
    >
      {children}
    </p>
  );
};

const Heading = ({ children, className, props, style }) => {
  return (
    <h2
      className={[styles.heading, className].join(' ')}
      {...props}
      style={style}
    >
      {children || 'heading'}
    </h2>
  );
};

export default Heading;
