import * as yup from 'yup';

const FILE_SIZE = 2000 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const signInValidationSchema = yup
  .object({
    email: yup
      .string()
      .email('Invalid Email!')
      .required('Please Enter Your Email'),
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(/(?=.{8,})/, 'Must Contain 8 Characters Maximum'),
  })
  .required();

export const signupValidationSchema = yup
  .object({
    firstName: yup.string().required('Required!'),
    lastName: yup.string().required('Required!'),
    email: yup
      .string()
      .email('Invalid Email!')
      .required('Please Enter Your Email'),
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(/(?=.{8,})/, 'Must Contain 8 Characters Maximum'),
  })
  .required();

export const passwordResetValidationSchema = yup
  .object({
    email: yup
      .string()
      .email('Invalid Email!')
      .required('Please Enter Your Email'),
  })
  .required();

export const newPasswordValidationSchema = yup
  .object({
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(/(?=.{8,})/, 'Must Contain 8 Characters Maximum'),
    cpassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Both passwords don’t match'),
  })
  .required();

export const bonusFormValidationSchema = yup
  .object({
    bonusName: yup.string().required('Please enter a value for bonus name'),
    bonusUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a value for bonus url'),
  })
  .required();

export const becomeVendorValidationSchema = yup
  .object({
    businessName: yup
      .string()
      .required('Please enter a value for business name'),
    businessDescription: yup
      .string()
      .required('Please enter a value for business name'),
    aboutMe: yup.string().required('Please enter a value for business name'),
    image: yup
      .mixed()
      .required('An Image is required')
      .test(
        'fileSize',
        'File too large, File must be less than 2mb',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  })
  .required();

// Edit vendor Profile Validation Schema
export const editVendorValidationSchema = yup
  .object({
    businessName: yup
      .string()
      .required('Please enter a value for business name'),
    businessDescription: yup
      .string()
      .required('Please enter a value for business name'),
    aboutMe: yup.string().required('Please enter a value for business name'),
    image: yup
      .mixed()
      .required('An Image is required')
      .test(
        'fileSize',
        'File too large, File must be less than 2mb',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  })
  .required();

export const productPublishFormValidationSchema = yup
  .object({
    price: yup.string().required('Please enter a price'),
    productName: yup.string().required('Please enter a product name'),
    productDescription: yup.string().required('Please enter a description'),
    commission: yup.string().required('Please enter a commission'),
    image: yup
      .mixed()
      .required('An Image is required')
      .test(
        'fileSize',
        'File too large, File must be less than 2mb',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    salesPageUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a sales page url'),
    thankYouPageUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a url for thank you page url'),
    marketingResUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a value for bonus name'),
  })
  .required();

yup.object().shape({
  text: yup.string().required('A text is required'),
  file: yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File too large',
      value => value && value.size <= FILE_SIZE
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

export const editProductFormValidationSchema = yup
  .object({
    price: yup.string().required('Please enter a price'),
    productName: yup.string().required('Please enter a product name'),
    productDescription: yup.string().required('Please enter a description'),
    commission: yup.string().required('Please enter a commission'),
    salesPageUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a sales page url'),
    thankYouPageUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a url for thank you page url'),
    marketingResUrl: yup
      .string()
      .url('Invalid URL!')
      .required('Please enter a value for bonus name'),
  })
  .required();

yup.object().shape({
  text: yup.string().required('A text is required'),
  file: yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File too large',
      value => value && value.size <= FILE_SIZE
    )
    .test(
      'fileFormat',
      'Unsupported Format please use png, jpeg',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

export const getApprovalSchema = yup
  .object({
    reqMsg: yup
      .string()
      .required('Please enter a request message')
      .min(5, 'Message must be at least 5 characters.'),
  })
  .required();

export const setUpContestValidationSchema = yup
  .object({
    contestTitle: yup
      .string()
      .required('Please enter a value for Contest name'),
    contestDescription: yup
      .string()
      .required('Please enter a value for contest description'),
    // firstPrize: yup.string().required('Please enter a value for first prize'),
    // secondPrize: yup.string().required('Please enter a value for first prize'),
    // thirdPrize: yup.string().required('Please enter a value for first prize'),
  })
  .required();
