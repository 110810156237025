import Cookie from 'universal-cookie';
import api from '../api-client';

const cookie = new Cookie();
// Get Item from LocalStorage
export const getItemFromLocalStorage = item => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(item);
  }
};

// Set Item to LocalStorage
export const setItemToLocalStorage = (key, value) => {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(key, value);
  }
};

// Clear LocalStorage
export const clearLocalStorage = (key, value) => {
  if (typeof window !== 'undefined') {
    cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    return localStorage.clear();
  }
};

// Convert JSON to JS object
export const parseJSON = data =>
  typeof window !== 'undefined' && JSON.parse(data);

// Set Authorization header
export const setAuthHeader = token => {
  api.defaults.headers.common['authorization'] = `Bearer ${token}`;
};

// Logout
export const logout = () => {
  cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  localStorage.clear();
};
