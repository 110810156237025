// import axios from 'axios';
// import Cookie from 'universal-cookie';
// import { toast } from 'react-toastify';

// const cookies = new Cookie();

// const authToken = cookies.get('authToken');

// const BASE_URL =
//   process.env.NEXT_PUBLIC_API_URL || 'https://xxx.stakecut.com/api/';
  
// const api = axios.create({
//   baseURL: BASE_URL,
//   timeout: 60000,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// export const authClient = axios.create({
//   baseURL: BASE_URL,
//   timeout: 100000,
//   headers: {
//     'Content-Type': 'application/json',
//     authorization: `Bearer ${authToken}`,
//   },
// });

// authClient.defaults.headers.authorization = `Bearer ${authToken}`;

// export const apiClient = (url, data, method) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await authClient.get(url, data, method);
//       resolve(res);
//     } catch (error) {
//       if (error.response) {
//         const { status, data } = error.response;
//         if (status === 401) {
//           cookies.remove('authToken', { path: '/' });
//           cookies.remove('user', { path: '/' });
//           cookies.remove('userId', { path: '/' });
//           toast.error('Your session has expired. Please login again.');
//           window.location.href = '/auth/sign-in';
//         } else if (status === 400) {
//           toast.error(data.message || 'Bad Request: Invalid request data.');
//         } else if (status === 403) {
//           toast.error('Forbidden: You do not have permission to access this resource.');
//         } else if (status === 404) {
//           toast.error('Not Found: The requested product could not be found.');
//         } else if (status === 500) {
//           toast.error('Server Error: Please try again later.');
//         } else {
//           toast.error(data.message || 'An error occurred while fetching the product details.');
//         }
//       } else if (error.request) {
//         toast.error('Network Error: Please check your internet connection and try again.');
//       } else {
//         toast.error('Error: ' + error.message);
//       }
//       reject(error);
//     }
//   });
// };

// export const getRefreshToken = async data => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await authClient.get(`/users/refresh-token`, data);
//       resolve(res);
//     } catch (error) {
//       if (error.response) {
//         const { status, data } = error.response;
//         if (status === 401) {
//           cookies.remove('authToken', { path: '/' });
//           cookies.remove('user', { path: '/' });
//           cookies.remove('userId', { path: '/' });
//           toast.error('Your session has expired. Please login again.');
//           window.location.href = '/auth/sign-in';
//         } else if (status === 400) {
//           toast.error(data.message || 'Bad Request: Invalid request data.');
//         } else if (status === 403) {
//           toast.error('Forbidden: You do not have permission to access this resource.');
//         } else if (status === 404) {
//           toast.error('Not Found: The requested product could not be found.');
//         } else if (status === 500) {
//           toast.error('Server Error: Please try again later.');
//         } else {
//           toast.error(data.message || 'An error occurred while fetching the product details.');
//         }
//       } else if (error.request) {
//         toast.error('Network Error: Please check your internet connection and try again.');
//       } else {
//         toast.error('Error: ' + error.message);
//       }
//       reject(error);
//     }
//   });
// };

// export default api;

// // import axios from 'axios';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import * as cookie from 'cookie';
// import * as setCookie from 'set-cookie-parser';
// import { logout } from '../utils/local-storage';

// // Create axios instance.
// export const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   withCredentials: true,
// });

// // Create axios interceptor
// createAuthRefreshInterceptor(axiosInstance, failedRequest =>
//   // 1. First try request fails - refresh the token.
//   axiosInstance.get('/api/refreshToken').then(resp => {
//     // 1a. Clear old helper cookie used in 'authorize.ts' higher order function.
//     if (axiosInstance.defaults.headers.setCookie) {
//       delete axiosInstance.defaults.headers.setCookie;
//     }
//     const { accessToken } = resp.data;
//     // 2. Set up new access token
//     const bearer = `Bearer ${accessToken}`;
//     axiosInstance.defaults.headers.Authorization = bearer;

//     // 3. Set up new refresh token as cookie
//     const responseCookie = setCookie.parse(resp.headers['set-cookie'])[0]; // 3a. We can't just acces it, we need to parse it first.
//     axiosInstance.defaults.headers.setCookie = resp.headers['set-cookie']; // 3b. Set helper cookie for 'authorize.ts' Higher order Function.
//     axiosInstance.defaults.headers.cookie = cookie.serialize(
//       responseCookie.name,
//       responseCookie.value
//     );
//     // 4. Set up access token of the failed request.
//     failedRequest.response.config.headers.Authorization = bearer;

//     // 5. Retry the request with new setup!
//     return Promise.resolve();
//   })
// );


import axios from 'axios';
import Cookie from 'universal-cookie';
import { toast } from 'react-toastify';

const cookies = new Cookie();
const authToken = cookies.get('authToken');

const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'https://xxx.stakecut.com/api/';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authClient = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${authToken}`,
  },
});

authClient.defaults.headers.authorization = `Bearer ${authToken}`;

export const apiClient = (url, data, method) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await authClient.get(url, data, method);
      resolve(res);
    } catch (error) {
      handleApiError(error);
      reject(error);
    }
  });
};

export const getRefreshToken = async data => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await authClient.get(`/users/refresh-token`, data);
      resolve(res);
    } catch (error) {
      handleApiError(error);
      reject(error);
    }
  });
};

const handleApiError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 401) {
      handleSessionExpired();
    } else if (status === 400) {
      toast.error(data.message || 'Bad Request: Invalid request data.');
    } else if (status === 403) {
      toast.error('Forbidden: You do not have permission to access this resource.');
    } else if (status === 404) {
      toast.error('Not Found: The requested product could not be found.');
    } else if (status === 500) {
      toast.error('Server Error: Please try again later.');
    } else {
      toast.error(data.message || 'An error occurred while fetching the product details.');
    }
  } else if (error.request) {
    toast.error('Network Error: Please check your internet connection and try again.');
  } else {
    toast.error('Error: ' + error.message);
  }
};

const handleSessionExpired = () => {
  cookies.remove('authToken', { path: '/' });
  cookies.remove('user', { path: '/' });
  cookies.remove('userId', { path: '/' });
  toast.error('Your session has expired. Please login again.');
  if (typeof window !== 'undefined') {
    window.location.href = '/auth/sign-in';
  }
};

// Token removal and inactivity handling
let inactivityTimer;

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    handleSessionExpired();
  }, 60 * 60 * 1000); // 2 minutes
};

const setupInactivityTimer = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);
    resetInactivityTimer(); // Initialize the timer
  }
};

setupInactivityTimer();

export default api;

// import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as cookie from 'cookie';
import * as setCookie from 'set-cookie-parser';
import { logout } from '../utils/local-storage';

// Create axios instance.
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Create axios interceptor
createAuthRefreshInterceptor(axiosInstance, failedRequest =>
  axiosInstance.get('/api/refreshToken').then(resp => {
    if (axiosInstance.defaults.headers.setCookie) {
      delete axiosInstance.defaults.headers.setCookie;
    }
    const { accessToken } = resp.data;
    const bearer = `Bearer ${accessToken}`;
    axiosInstance.defaults.headers.Authorization = bearer;

    const responseCookie = setCookie.parse(resp.headers['set-cookie'])[0];
    axiosInstance.defaults.headers.setCookie = resp.headers['set-cookie'];
    axiosInstance.defaults.headers.cookie = cookie.serialize(
      responseCookie.name,
      responseCookie.value
    );
    failedRequest.response.config.headers.Authorization = bearer;

    return Promise.resolve();
  })
);
